import React from "react"
import cx from 'classnames'
import BlockContent from "@sanity/block-content-to-react"
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import { handleSlug } from 'src/utils/handleSlug'
import { Image } from 'src/components/image'
import { Serializer } from "src/utils/serializer"

import { Tab } from 'src/components/svgs'
import { RotatingText } from 'src/utils/rotatingText'

export interface TwoColumnModuleProps {
  data: {
    cols: any[]
  }
}

function circularText(txt, radius, classIndex) {
  txt = txt.split("")

  const deg = 360 / txt.length
  let origin = 0

  let textString = ``

  txt.forEach((ea) => {
    ea = `<span class='ss12' style='height:${radius}px;top:8px;left:60px;position:absolute;transform:rotate(${origin}deg);transform-origin:0 100%'>${ea}</span>`;
    textString += ea;
    origin += deg;
  });
  return textString
}

const handleLink = (asset, text, link) => {

  switch (link._type) {
    case 'file':
      return <a className='button dib bn button--large founders' href={`${asset.url}?dl=${asset.originalFilename}`}>
        <span className=''>{text}</span>
      </a>
    case 'externalLink':
      return <a className='button dib bn button--large founders' href={link.link} target='_blank' rel='noreferrer'>
        <span className=''>{text}</span>
      </a>
    case 'internalLink':
      return <Link className='button dib bn button--large founders' to={handleSlug(link.link._type, link.link.slug)}>
        <span className=''>{text}</span>
      </Link>
    default:
      return null
  }
}

export const TwoColumnModule = ({ data }: TwoColumnModuleProps) => {
  const { cols } = data
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true
  })

  const handleCol = (col: any) => {

    switch (col._type) {
      case 'standardText':
        return (
          <>
            <div className='p3--600 y df jcc aic p5--800 tc--800 x' ref={ref}>
              <div className={cx('container--500 p2 tl tc--800 mxa rich__text  animate-text',  inView && 'animated')}>
                <BlockContent blocks={col.text} serializers={Serializer} />
              </div>
            </div>
            <div>
              {col.ctaText && col.ctaLink && (
                <div className='x tc df jcc aic bc bt'>
                  {handleLink(col.ctaLink[0].asset, col.ctaText, col.ctaLink[0])} 
                </div>
              )}
            </div>
          </>
        )
      case 'imageAndBadge':
        return (
          <div className='p3--600 image__badge pr p4--1000 x'>
            {col.badgeText && (
              <div className={cx('pa right z1 image__badge-wrapper top', `color--blue`)}>
                <Tab />
                <div className='pa top right badge__text founders color--white caps'>
                  <RotatingText text={col.badgeText ? col.badgeText : 'exactly'} />
                </div>
              </div>
            )}
            <Image imageId={col.image} src={col.imageUrl} alt={col.badgeText} />
          </div>
        )
      default:
        return (
          <span className='empty'></span>
        )
    }
  }
  return (
    <div className='p2 mt4 mb2 mt8--800 mb4--800'>
      <div className="container--xl p2--800 outer mxa  x">
        <div className='ba bc'>
          <div className='f jcb fw aist fdrr x'>
            {cols.map((col, i) => (
              <div className={cx('x grid--50-800 bc f jcb fdc', {
                'bl--800': i === 0
              })} key={col._key}>
                {handleCol(col)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}