import React from "react"
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

export interface TextHeroProps {
  data: {
    title: string
    backgroundColor: string
  }
}

export const TextHero = ({ data }: TextHeroProps) => {
  const { backgroundColor, title } = data
  return (
    <div className={cx(`bg--${backgroundColor} bb p1 df jcs y text__hero aie`, {
      'color--white': backgroundColor === 'green'
    })}>
      <div className="outer mxa pt6 pb2--800  p1 tl x">
        <h1 className='italic'>{title}</h1>
      </div>
    </div>
  )
}