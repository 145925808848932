import React from "react"

import { Image } from 'src/components/image'

export interface ImageModuleProps {
  data: {
    image: string
    altText?: string
  }
}

export const ImageModule = ({ data }: ImageModuleProps) => {
  const { image, altText } = data
  return (
    <div className="x al p mb4 mt4 mt6--800 mb6--600">
      <Image className='x' imageId={image.imageId} alt={altText} />
    </div>
  )
}