import React from 'react'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'

export interface StandardTextProps {
  data: {
    text: any[]
  }
}

export const StandardText = ({ data }: StandardTextProps) => {
  const { text } = data
  return (
    <div className='container--xl outer mxa rich__text py2 p2 al p x'>
      <BlockContent blocks={text} serializers={Serializer} />
    </div>
  )
}