import React, { useEffect } from "react";
import cx from "classnames";

import { useSelect } from "dropkit";

const Dropdown = ({
  list,
  defaultLabel,
  updateVariant
}: {
  list: any[]
  defaultLabel: string
  updateVariant: () => void
}) => {
  const [label, labelSet] = React.useState(defaultLabel || "Please Select");

  const { id, items, isOpen, getControlProps, getDropProps } = useSelect({
    items: list,
    onSelect(item) {
      updateVariant(item)
      labelSet(item.label); // set to active item
    },
    onRemove() {
      labelSet("Please select"); // reset to placeholder
    }
  });

  useEffect(() => {

  }, [])

  return (
    <div className='pr y z6'>
      <button type='reset' className={cx('m0 p1 df jcb aic x y lars tl button button--md dropdown__button br', isOpen && 'open')} id={id} {...getControlProps()}>
        {label}
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M5 3.99275L8.99565 -4.39015e-08L10 1.00362L5 6L-4.38697e-08 1.00362L1.00435 -3.93212e-07L5 3.99275Z" fill="currentColor"/>
        </svg>
      </button>

      {isOpen && (
        <ul className='dropdown__list pa x z3' {...getDropProps()}>
          {items.map((i) => (
            <li
              key={i.value}
              className={cx('s14 bold lars', {
                "is-selected": i.selected,
                "is-highlighted": i.highlighted
              })}
              {...i.getItemProps()}
            >
              {i.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown