import React from 'react'

export const RotatingText = ({ text }: { text: string}) => {
  switch(text) {
    case 'perfectly-portioned': 
    return (
      <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
        <path d="M11.2 45.7L10.7 47.6L1 45L2 41.1C2.7 38.6 4.2 37.5 6.2 38C8.2 38.5 9 40.3 8.4 42.8L7.9 44.8L11.2 45.7ZM6.8 42.4C7.2 40.9 6.8 40.2 5.8 39.9C4.8 39.6 4 40.1 3.6 41.5L3.1 43.4L6.3 44.3L6.8 42.4Z" fill="white"/>
        <path d="M16.6 32.2L13.2 38.9L4.20001 34.4L7.50001 27.8L9.00001 28.5L6.60001 33.3L8.80001 34.4L10.7 30.6L12.2 31.3L10.3 35.1L12.7 36.3L15.2 31.3L16.6 32.2Z" fill="white"/>
        <path d="M21.3 26C21 26 20.3 25.7 19.4 25C18.5 24.4 17.7 24.4 17 25.3L15.5 27.1L18.4 29.4L17.2 30.9L9.29999 24.6L12.1 21C13.7 19 15.4 18.8 16.9 19.9C18.1 20.8 18.2 22.2 17.5 23.2C18.4 22.4 19.3 22.4 20.3 23C21.5 23.8 22 24.1 22.5 24.1L21.3 26ZM15.8 24.4C16.7 23.3 16.8 22.4 15.9 21.8C15.1 21.2 14.2 21.3 13.3 22.5L11.9 24.3L14.4 26.3L15.8 24.4Z" fill="white"/>
        <path d="M23.6 23.6L17.2 15.8L22.8 11.2L23.9 12.5L19.9 15.8L21.6 17.9L24.7 15.3L25.7 16.6L22.6 19.2L25.2 22.4L23.6 23.6Z" fill="white"/>
        <path d="M36.8 14.8L30.2 18.4L25.4 9.5L31.9 6L32.7 7.5L27.9 10.1L29.1 12.3L32.8 10.3L33.6 11.8L29.9 13.8L31.2 16.1L36.1 13.5L36.8 14.8Z" fill="white"/>
        <path d="M39.1 3.19999C42 2.39999 44.1 3.69998 44.9 6.09998L43.1 6.69999C42.5 5.29999 41.3 4.39999 39.6 4.89999C37.9 5.39999 37.1 6.99998 37.7 9.09998C38.3 11.1 39.8 12.1 41.5 11.6C43.3 11.1 43.8 9.69999 43.5 8.19999L45.4 7.69999C45.9 10.2 44.9 12.5 42 13.3C39.2 14.1 36.5 12.7 35.7 9.69999C34.9 6.69999 36.4 3.99999 39.1 3.19999Z" fill="white"/>
        <path d="M50.3 11.5L49.8 3.09999L46.2 3.3L46.1 1.59999L55.3 1L55.4 2.7L51.8 2.89999L52.3 11.3L50.3 11.5Z" fill="white"/>
        <path d="M60.4 1.59999L59.2 9.89999L64.5 10.6L64.3 12.2L57 11.2L58.4 1.2L60.4 1.59999Z" fill="white"/>
        <path d="M73.3 4.7L75.4 5.39999L69.8 10.1L68.6 13.8L66.7 13.2L67.9 9.5L66.1 2.39999L68.2 3.09999L69.5 8L73.3 4.7Z" fill="white"/>
        <path d="M82.4 21.6L80.9 20.3L87.7 12.8L90.7 15.5C92.6 17.2 92.9 19.1 91.5 20.7C90.1 22.3 88.2 22.2 86.3 20.4L84.8 19L82.4 21.6ZM87.3 19.1C88.4 20.1 89.3 20.1 90 19.3C90.7 18.5 90.7 17.6 89.5 16.6L88 15.3L85.7 17.8L87.3 19.1Z" fill="white"/>
        <path d="M90.1 30.3C88.4 27.9 88.9 24.9 91.5 23.1C94 21.3 97 21.9 98.7 24.3C100.4 26.7 99.9 29.7 97.3 31.5C94.7 33.3 91.8 32.7 90.1 30.3ZM97.2 25.3C96.1 23.8 94.3 23.6 92.6 24.8C90.9 26 90.4 27.8 91.5 29.3C92.6 30.8 94.4 31 96.1 29.8C97.9 28.6 98.3 26.8 97.2 25.3Z" fill="white"/>
        <path d="M96 41.5C96.2 41.2 96.8 40.8 97.8 40.3C98.8 39.8 99.1 39.1 98.7 38.1L97.8 35.9L94.3 37.3L93.6 35.5L103 31.8L104.7 36C105.6 38.4 105 40 103.3 40.7C101.9 41.3 100.7 40.7 100.1 39.6C100.3 40.8 99.9 41.6 98.8 42.1C97.5 42.8 97 43 96.8 43.4L96 41.5ZM100.1 37.5C100.6 38.9 101.3 39.3 102.3 38.9C103.2 38.5 103.5 37.7 103 36.3L102.1 34.1L99.2 35.3L100.1 37.5Z" fill="white"/>
        <path d="M97.5 47.3L105.8 46L105.2 42.5L106.8 42.2L108.3 51.3L106.7 51.6L106.1 48L97.8 49.3L97.5 47.3Z" fill="white"/>
        <path d="M108.3 55.9L98.2 56.1V54.1L108.3 53.9V55.9Z" fill="white"/>
        <path d="M97.4 62.5C97.9 59.6 100.3 57.8 103.4 58.3C106.5 58.8 108.2 61.3 107.7 64.2C107.2 67.1 104.8 68.9 101.7 68.4C98.7 67.9 97 65.4 97.4 62.5ZM106 63.9C106.3 62.1 105.1 60.7 103.1 60.3C101 60 99.5 60.9 99.2 62.7C98.9 64.5 100.1 65.9 102.1 66.3C104.2 66.7 105.7 65.7 106 63.9Z" fill="white"/>
        <path d="M96.9 76.1L103.1 78.7L102.4 80.4L93.1 76.5L93.7 75L102.2 73L95.7 70.2L96.4 68.5L105.7 72.4L104.9 74.2L96.9 76.1Z" fill="white"/>
        <path d="M87.8 84L92.2001 77.9L100.4 83.7L96.1 89.7L94.7001 88.7L97.8 84.3L95.8 82.9L93.3 86.4L92.0001 85.4L94.5001 81.9L92.3 80.4L89.1 84.9L87.8 84Z" fill="white"/>
        <path d="M93.8 92.5L91 95.1C88.6 97.3 85.8 97.2 83.7 94.9C81.6 92.6 81.8 89.8 84.2 87.6L87 85L93.8 92.5ZM85.4 88.7C83.8 90.1 83.8 91.9 85.3 93.5C86.7 95.1 88.5 95.2 90.1 93.8L91.3 92.7L86.7 87.6L85.4 88.7Z" fill="white"/>
        <path d="M71 100.1C70.4 98.6 69.2 98.3 67.8 98.7C66.6 99.1 66 99.8 66.3 100.6C66.5 101.3 67.2 101.4 68.3 101.3L70.2 101.1C71.8 100.9 73.1 101.4 73.6 103C74.2 104.8 73.1 106.4 70.9 107.1C68.4 107.9 66.6 107.1 65.8 104.9L67.5 104.3C68 105.6 69.1 106 70.4 105.6C71.5 105.2 72 104.5 71.7 103.7C71.5 103 70.9 102.9 70 103L68.1 103.2C66.1 103.5 64.9 102.8 64.4 101.3C63.8 99.4 65.1 97.9 67.3 97.2C69.8 96.4 72 97.1 72.7 99.4L71 100.1Z" fill="white"/>
        <path d="M60.4 99L62.4 98.8L63.4 108.8L59.4 109.2C56.8 109.5 55.3 108.4 55 106.3C54.7 104.2 56.1 102.9 58.7 102.6L60.7 102.4L60.4 99ZM58.9 104.3C57.4 104.5 56.8 105.1 56.9 106.2C57 107.3 57.7 107.8 59.2 107.7L61.2 107.5L60.9 104.2L58.9 104.3Z" fill="white"/>
        <path d="M50.7 109.4L51.2 99.3L53.2 99.4L52.7 109.5L50.7 109.4Z" fill="white"/>
        <path d="M42.8 108.5C39.9 107.9 38.7 105.7 39 103.2L40.9 103.5C40.7 105 41.4 106.4 43.2 106.8C45 107.2 46.4 106.1 46.8 104C47.2 101.9 46.4 100.4 44.6 100C42.8 99.6 41.7 100.6 41.2 102.1L39.3 101.6C40 99.1 42 97.7 44.9 98.3C47.7 98.9 49.4 101.4 48.8 104.5C48.2 107.6 45.6 109.1 42.8 108.5Z" fill="white"/>
        <path d="M32.3 93.8L39.1 96.9L34.9 106.1L28.2 103L28.9 101.5L33.8 103.8L34.8 101.6L30.9 99.8L31.6 98.3L35.5 100.1L36.6 97.7L31.5 95.4L32.3 93.8Z" fill="white"/>
        <path d="M28.1 95.4C29 94.1 28.5 92.8 27.3 92C26.3 91.3 25.4 91.2 24.9 91.9C24.4 92.5 24.8 93.1 25.5 93.9L26.8 95.3C27.9 96.5 28.3 97.8 27.3 99.1C26.2 100.6 24.3 100.7 22.4 99.3C20.3 97.7 19.9 95.8 21.2 93.9L22.7 94.9C21.9 96 22.2 97.2 23.4 98C24.4 98.7 25.2 98.7 25.7 98C26.1 97.4 25.9 96.9 25.2 96.2L23.9 94.8C22.5 93.3 22.3 92 23.3 90.7C24.5 89.1 26.5 89.2 28.3 90.6C30.4 92.1 31.1 94.3 29.7 96.3L28.1 95.4Z" fill="white"/>
      </svg>
    )
  case 'joy':
    return (
      <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
        <path d="M11.6 44.3L3.5 42.1L2.6 45.5L1 45.1L3.4 36.2L5 36.6L4 40.1L12.1 42.3L11.6 44.3Z" fill="white"/>
        <path d="M13.5 37.9L4.70001 33.1L5.70001 31.3L9.30001 33.3L11.7 28.9L8.10001 26.9L9.10001 25.1L17.9 29.9L16.9 31.7L13.1 29.6L10.7 34L14.5 36.1L13.5 37.9Z" fill="white"/>
        <path d="M23.8 23.1L18.8 28.8L11.2 22.2L16.1 16.6L17.4 17.7L13.8 21.8L15.6 23.4L18.4 20.2L19.6 21.3L16.8 24.5L18.8 26.3L22.5 22.1L23.8 23.1Z" fill="white"/>
        <path d="M34.2 4.69995L37 10.6C38.1 12.9 37.2 14.6 35.2 15.6C33.1 16.6 31.2 16 30 13.7L31.7 12.8C32.4 14.1 33.3 14.6 34.5 14C35.6 13.5 35.8 12.6 35.2 11.3L32.4 5.5L34.2 4.69995Z" fill="white"/>
        <path d="M44.5 12.4C41.6 13.1 39 11.5 38.3 8.39996C37.6 5.39996 39.3 2.79995 42.1 2.19995C45 1.49995 47.5 3.09995 48.2 6.19995C49 9.09995 47.4 11.7 44.5 12.4ZM42.6 3.89996C40.8 4.29996 39.9 5.9 40.4 8C40.9 10.1 42.3 11.1 44.1 10.7C45.9 10.3 46.8 8.69998 46.3 6.59998C45.9 4.49998 44.4 3.49996 42.6 3.89996Z" fill="white"/>
        <path d="M56.4 1H58.6L54.8 7.29999L54.9 11.2H52.9L52.8 7.29999L48.8 1.19995H51L53.8 5.39996L56.4 1Z" fill="white"/>
        <path d="M72.8 15.4C70.1 14.2 69.1 11.3 70.4 8.50001C71.7 5.70001 74.6 4.69997 77.3 5.89997C80 7.09997 81 9.99999 79.7 12.8C78.3 15.6 75.5 16.6 72.8 15.4ZM76.5 7.50001C74.8 6.70001 73.2 7.39997 72.3 9.39997C71.4 11.3 71.9 13 73.6 13.8C75.3 14.6 76.9 13.9 77.8 11.9C78.7 9.99997 78.2 8.30001 76.5 7.50001Z" fill="white"/>
        <path d="M78.4 18.1L84.5 10.1L90.2 14.5L89.2 15.8L85.1 12.6L83.5 14.7L86.7 17.2L85.7 18.5L82.5 16L80 19.2L78.4 18.1Z" fill="white"/>
        <path d="M103.2 31.6L97.4999 34.8C95.2999 36.1 93.4999 35.3 92.3999 33.3C91.1999 31.3 91.6999 29.3 93.8999 28L94.8999 29.6C93.6999 30.4 93.1999 31.4 93.8999 32.4C94.4999 33.5 95.3999 33.6 96.6999 32.9L102.3 29.7L103.2 31.6Z" fill="white"/>
        <path d="M98.7 36.6L104.6 34.8L105.2 36.7L99.5 38.5C97.8 39 97.2 40 97.7 41.5C98.2 43 99.2999 43.5 100.9 42.9L106.6 41.1L107.2 43L101.3 44.8C98.5 45.7 96.8 44.2 96.1 41.9C95.4 39.6 95.9 37.5 98.7 36.6Z" fill="white"/>
        <path d="M101.6 48.5C100 48.7 99.4 49.9 99.6 51.3C99.7 52.5 100.3 53.2999 101.1 53.1999C101.9 53.0999 102.1 52.4999 102.2 51.3999L102.4 49.5C102.6 47.9 103.3 46.8 105 46.6C106.9 46.4 108.2 47.7999 108.4 50.1999C108.6 52.7999 107.4 54.3999 105.2 54.6999L105 52.8999C106.4 52.6999 107 51.7999 106.9 50.3999C106.8 49.1999 106.2 48.5999 105.4 48.6999C104.7 48.7999 104.5 49.2999 104.4 50.1999L104.2 52.1C104 54.1 103.1 55.1 101.5 55.3C99.5 55.5 98.3 53.9 98.1 51.6C97.9 49 99.1 47 101.5 46.8L101.6 48.5Z" fill="white"/>
        <path d="M98.1001 59.3L106.5 60.3L106.9 56.8L108.6 57L107.5 66.1L105.8 65.9L106.2 62.3L97.8 61.3L98.1001 59.3Z" fill="white"/>
        <path d="M90 81.1L93.9 74.6L102.5 79.8L98.7 86.1L97.3 85.2L100.1 80.5L98 79.2L95.8 82.8L94.4 82L96.6 78.4L94.3 77L91.4 81.8L90 81.1Z" fill="white"/>
        <path d="M87.1 89.3L92 93.9L90.7 95.3L83.4 88.4L84.5 87.2L93.2 88.3L88 83.4L89.3 82L96.6 88.9L95.2 90.3L87.1 89.3Z" fill="white"/>
        <path d="M78.2 91.8C80.7 90.2 83.7 90.8 85.3 93.4C87 96 86.3 99 83.9 100.6C81.4 102.2 78.5 101.6 76.8 99C75.1 96.4 75.7 93.4 78.2 91.8ZM82.9 99.1C84.5 98.1 84.7 96.3 83.5 94.5C82.3 92.7 80.6 92.2 79.1 93.3C77.5 94.3 77.3 96.1 78.5 97.9C79.7 99.7 81.4 100.1 82.9 99.1Z" fill="white"/>
        <path d="M74.3001 98.8L76.4001 104.6L74.5001 105.3L72.5001 99.7C71.9001 98.1 70.9001 97.5 69.4001 98C67.9001 98.5 67.5001 99.6 68.1001 101.3L70.1001 106.9L68.2001 107.6L66.1001 101.8C65.1001 99 66.5001 97.3 68.8001 96.5C71.1001 95.7 73.4001 96.1 74.3001 98.8Z" fill="white"/>
        <path d="M64.5 103.3C64.8 106.4 62.8 108.7 60 109C57.1 109.3 55.3 107.9 54.8 105.4L56.7 105.1C57 106.6 58.1 107.4 59.7 107.3C61.5 107.1 62.6 105.6 62.4 103.5C62.2 101.4 60.8 100.1 58.9 100.3C57.5 100.4 56.5 101.4 56.4 102.7L59.1 102.4L59.3 103.9L54.8 104.4L54.3 99.3L56.1 99.1L56.3 100.6C56.7 99.4 57.9 98.7 59.3 98.6C61.9 98.4 64.2 100.2 64.5 103.3Z" fill="white"/>
        <path d="M52.6 99.3L51.2 109.3L49.2 109L49.8 104.9L44.9 104.2L44.3 108.3L42.3 108L43.7 98L45.7 98.3L45.1 102.6L50 103.3L50.6 99L52.6 99.3Z" fill="white"/>
      </svg>
    )
  case 'super-fresh':
    return (
      <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
        <path d="M7.99697 45.2C9.49697 45.5 10.497 44.6 10.897 43.3C11.197 42.1 10.997 41.2 10.097 41C9.39698 40.8 8.89697 41.3 8.39697 42.3L7.59698 44.1C6.89698 45.5 5.79699 46.4 4.19699 45.9C2.39699 45.4 1.59699 43.7 2.19699 41.4C2.89699 38.8 4.59696 37.8 6.79696 38.3L6.39697 40.1C5.09697 39.8 4.09696 40.5 3.79696 41.8C3.49696 42.9 3.79699 43.7 4.69699 44C5.39699 44.2 5.79699 43.7 6.19699 42.9L6.99697 41.2C7.89697 39.4 8.99698 38.7 10.597 39.2C12.497 39.7 13.097 41.6 12.497 43.8C11.797 46.3 9.99699 47.8 7.69699 47.1L7.99697 45.2Z" fill="white"/>
        <path d="M10.197 36.9L4.69702 34.1L5.59702 32.3L10.897 35.1C12.397 35.9 13.597 35.7 14.297 34.3C14.997 32.9 14.597 31.8 13.097 31L7.797 28.2L8.69702 26.4L14.197 29.2C16.797 30.6 16.997 32.8 15.797 34.9C14.597 37 12.797 38.3 10.197 36.9Z" fill="white"/>
        <path d="M19.797 28.3L18.497 29.8L10.797 23.3L13.397 20.2C15.097 18.2 16.897 17.9 18.497 19.2C20.097 20.5 20.097 22.5 18.397 24.4L17.097 26L19.797 28.3ZM17.197 23.6C18.197 22.4 18.097 21.6 17.297 20.9C16.497 20.2 15.597 20.3 14.597 21.5L13.297 23L15.897 25.2L17.197 23.6Z" fill="white"/>
        <path d="M30.397 18.3L24.497 23L18.197 15.1L23.997 10.5L24.997 11.8L20.797 15.2L22.297 17.1L25.597 14.4L26.597 15.7L23.297 18.4L24.997 20.5L29.397 17L30.397 18.3Z" fill="white"/>
        <path d="M37.2969 14.7C36.9969 14.5 36.5969 14 35.9969 13C35.3969 12 34.697 11.8 33.697 12.3L31.597 13.4L33.2969 16.7L31.4969 17.6L26.9969 8.60002L31.097 6.60002C33.397 5.50002 35.0969 6.00002 35.8969 7.60002C36.5969 9.00002 36.0969 10.2 34.9969 10.9C36.0969 10.6 36.997 10.9 37.597 11.9C38.397 13.2 38.597 13.6 39.097 13.8L37.2969 14.7ZM32.9969 11C34.2969 10.4 34.697 9.60002 34.197 8.60002C33.797 7.70002 32.897 7.50002 31.597 8.10002L29.4969 9.10002L30.8969 11.9L32.9969 11Z" fill="white"/>
        <path d="M49.3969 11.4L48.9969 1.29999L56.197 1L56.2969 2.69998L51.097 2.89999L51.197 5.60001L55.197 5.39999L55.2969 7L51.2969 7.19998L51.4969 11.3L49.3969 11.4Z" fill="white"/>
        <path d="M64.197 12.2C63.997 11.9 63.997 11.2 64.097 10.1C64.197 9.00001 63.797 8.39998 62.597 8.19998L60.297 7.79999L59.697 11.5L57.797 11.2L59.397 1.19998L63.897 1.89999C66.397 2.29999 67.497 3.7 67.197 5.5C66.997 7 65.797 7.69998 64.597 7.69998C65.697 8.09998 66.197 8.9 66.097 10C65.997 11.5 65.997 12 66.197 12.4L64.197 12.2ZM62.797 6.60001C64.197 6.80001 64.997 6.49999 65.197 5.39999C65.397 4.39999 64.797 3.7 63.397 3.5L61.097 3.10001L60.597 6.19998L62.797 6.60001Z" fill="white"/>
        <path d="M74.297 15.6L67.297 12.9L70.997 3.5L77.897 6.19998L77.297 7.79999L72.197 5.79999L71.297 8.10001L75.297 9.60001L74.697 11.1L70.697 9.60001L69.697 12.1L74.897 14.1L74.297 15.6Z" fill="white"/>
        <path d="M78.397 13.8C77.597 15.2 78.197 16.4 79.397 17.1C80.397 17.8 81.397 17.8 81.797 17.1C82.197 16.5 81.897 15.9 81.097 15.1L79.697 13.8C78.497 12.7 78.097 11.4 78.997 9.99999C79.997 8.39999 81.897 8.19999 83.897 9.49999C86.097 10.9 86.597 12.9 85.397 14.8L83.797 13.9C84.497 12.7 84.097 11.6 82.997 10.9C81.997 10.3 81.197 10.3 80.697 11.1C80.297 11.7 80.597 12.2 81.297 12.9L82.697 14.2C84.197 15.6 84.397 16.9 83.497 18.3C82.397 20 80.397 19.9 78.497 18.7C76.297 17.3 75.497 15.1 76.797 13.1L78.397 13.8Z" fill="white"/>
        <path d="M82.9969 21.2L90.197 14.1L91.597 15.5L88.697 18.4L92.197 22L95.097 19.1L96.4969 20.5L89.2969 27.6L87.8969 26.2L90.9969 23.2L87.4969 19.6L84.3969 22.6L82.9969 21.2Z" fill="white"/>
        <path d="M101.097 66.6C99.597 66.2 98.597 67.1 98.197 68.5C97.797 69.7 98.0969 70.6 98.8969 70.8C99.5969 71 100.097 70.5 100.597 69.6L101.497 67.9C102.197 66.5 103.397 65.7 104.997 66.2C106.797 66.7 107.497 68.5 106.897 70.8C106.097 73.3 104.397 74.4 102.297 73.7L102.797 71.9C104.097 72.2 105.097 71.6 105.497 70.3C105.797 69.2 105.497 68.4 104.697 68.1C103.997 67.9 103.597 68.3 103.197 69.1L102.297 70.8C101.397 72.6 100.197 73.2 98.597 72.7C96.697 72.1 96.1969 70.2 96.7969 68C97.4969 65.5 99.397 64.1 101.697 64.8L101.097 66.6Z" fill="white"/>
        <path d="M98.697 74.9L104.097 77.9L103.097 79.6L97.897 76.7C96.397 75.8 95.197 76.1 94.497 77.4C93.697 78.8 94.097 79.9 95.597 80.7L100.797 83.6L99.797 85.3L94.397 82.3C91.797 80.9 91.697 78.6 92.897 76.5C94.197 74.5 96.097 73.4 98.697 74.9Z" fill="white"/>
        <path d="M88.7969 83.2L90.0969 81.7L97.5969 88.4L94.8969 91.4C93.1969 93.3 91.2969 93.6 89.6969 92.2C88.0969 90.8 88.1969 88.9 89.8969 87L91.2969 85.5L88.7969 83.2ZM91.2969 88C90.2969 89.1 90.2969 90 91.0969 90.7C91.8969 91.4 92.7969 91.3 93.7969 90.2L95.0969 88.7L92.5969 86.5L91.2969 88Z" fill="white"/>
        <path d="M77.897 92.9L83.897 88.4L89.897 96.5L83.997 100.9L82.997 99.6L87.297 96.3L85.797 94.3L82.397 96.9L81.397 95.6L84.797 93L83.197 90.9L78.697 94.2L77.897 92.9Z" fill="white"/>
        <path d="M70.897 96.3C71.197 96.5 71.597 97 72.097 98C72.597 99 73.297 99.2 74.397 98.8L76.497 97.8L74.997 94.4L76.797 93.6L80.997 102.8L76.897 104.7C74.597 105.8 72.897 105.2 72.097 103.5C71.497 102.1 71.997 100.9 72.997 100.2C71.897 100.5 70.997 100.1 70.397 99.1C69.697 97.8 69.397 97.4 68.897 97.2L70.897 96.3ZM75.097 100.1C73.797 100.7 73.397 101.4 73.797 102.4C74.197 103.3 75.097 103.6 76.397 103L78.497 102L77.197 99.1L75.097 100.1Z" fill="white"/>
        <path d="M58.697 99.2L58.897 109.3L51.697 109.4V107.7L56.897 107.6V104.9L52.897 105V103.4L56.897 103.3L56.797 99.2H58.697Z" fill="white"/>
        <path d="M43.997 98C44.197 98.3 44.097 99 43.997 100.1C43.897 101.2 44.297 101.8 45.397 102L47.697 102.4L48.397 98.7L50.297 99.1L48.397 109L43.897 108.1C41.397 107.6 40.397 106.2 40.697 104.4C40.997 102.9 42.097 102.2 43.397 102.3C42.297 101.9 41.897 101.1 41.997 99.9C42.197 98.4 42.197 97.9 41.897 97.5L43.997 98ZM45.197 103.6C43.797 103.3 42.997 103.7 42.797 104.7C42.597 105.7 43.197 106.4 44.597 106.7L46.897 107.1L47.497 104L45.197 103.6Z" fill="white"/>
        <path d="M33.8969 94.2L40.7969 97.1L36.8969 106.4L30.0969 103.5L30.7969 102L35.7969 104.1L36.7969 101.8L32.8969 100.1L33.4969 98.6L37.3969 100.3L38.3969 97.9L33.2969 95.7L33.8969 94.2Z" fill="white"/>
        <path d="M29.697 95.9C30.497 94.6 29.997 93.3 28.797 92.5C27.797 91.8 26.797 91.7 26.397 92.4C25.997 93 26.297 93.6 27.097 94.4L28.497 95.8C29.597 96.9 29.997 98.3 29.097 99.6C27.997 101.2 26.097 101.3 24.197 100C21.997 98.5 21.597 96.5 22.797 94.7L24.397 95.7C23.697 96.9 23.997 98 25.097 98.8C26.097 99.5 26.897 99.4 27.397 98.7C27.797 98.1 27.497 97.6 26.897 96.9L25.597 95.6C24.197 94.2 23.897 92.9 24.897 91.5C25.997 89.9 27.997 90 29.897 91.2C31.997 92.7 32.797 94.8 31.397 96.8L29.697 95.9Z" fill="white"/>
        <path d="M25.397 88.4L17.997 95.2L16.597 93.7L19.597 90.9L16.197 87.2L13.197 90L11.797 88.5L19.197 81.7L20.597 83.2L17.397 86.1L20.797 89.8L23.997 86.9L25.397 88.4Z" fill="white"/>
      </svg>
    )
  case 'Fresher Test':
    return (
      <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
        <path d="M7.99697 45.2C9.49697 45.5 10.497 44.6 10.897 43.3C11.197 42.1 10.997 41.2 10.097 41C9.39698 40.8 8.89697 41.3 8.39697 42.3L7.59698 44.1C6.89698 45.5 5.79699 46.4 4.19699 45.9C2.39699 45.4 1.59699 43.7 2.19699 41.4C2.89699 38.8 4.59696 37.8 6.79696 38.3L6.39697 40.1C5.09697 39.8 4.09696 40.5 3.79696 41.8C3.49696 42.9 3.79699 43.7 4.69699 44C5.39699 44.2 5.79699 43.7 6.19699 42.9L6.99697 41.2C7.89697 39.4 8.99698 38.7 10.597 39.2C12.497 39.7 13.097 41.6 12.497 43.8C11.797 46.3 9.99699 47.8 7.69699 47.1L7.99697 45.2Z" fill="white"/>
        <path d="M10.197 36.9L4.69702 34.1L5.59702 32.3L10.897 35.1C12.397 35.9 13.597 35.7 14.297 34.3C14.997 32.9 14.597 31.8 13.097 31L7.797 28.2L8.69702 26.4L14.197 29.2C16.797 30.6 16.997 32.8 15.797 34.9C14.597 37 12.797 38.3 10.197 36.9Z" fill="white"/>
        <path d="M19.797 28.3L18.497 29.8L10.797 23.3L13.397 20.2C15.097 18.2 16.897 17.9 18.497 19.2C20.097 20.5 20.097 22.5 18.397 24.4L17.097 26L19.797 28.3ZM17.197 23.6C18.197 22.4 18.097 21.6 17.297 20.9C16.497 20.2 15.597 20.3 14.597 21.5L13.297 23L15.897 25.2L17.197 23.6Z" fill="white"/>
        <path d="M30.397 18.3L24.497 23L18.197 15.1L23.997 10.5L24.997 11.8L20.797 15.2L22.297 17.1L25.597 14.4L26.597 15.7L23.297 18.4L24.997 20.5L29.397 17L30.397 18.3Z" fill="white"/>
        <path d="M37.2969 14.7C36.9969 14.5 36.5969 14 35.9969 13C35.3969 12 34.697 11.8 33.697 12.3L31.597 13.4L33.2969 16.7L31.4969 17.6L26.9969 8.60002L31.097 6.60002C33.397 5.50002 35.0969 6.00002 35.8969 7.60002C36.5969 9.00002 36.0969 10.2 34.9969 10.9C36.0969 10.6 36.997 10.9 37.597 11.9C38.397 13.2 38.597 13.6 39.097 13.8L37.2969 14.7ZM32.9969 11C34.2969 10.4 34.697 9.60002 34.197 8.60002C33.797 7.70002 32.897 7.50002 31.597 8.10002L29.4969 9.10002L30.8969 11.9L32.9969 11Z" fill="white"/>
        <path d="M49.3969 11.4L48.9969 1.29999L56.197 1L56.2969 2.69998L51.097 2.89999L51.197 5.60001L55.197 5.39999L55.2969 7L51.2969 7.19998L51.4969 11.3L49.3969 11.4Z" fill="white"/>
        <path d="M64.197 12.2C63.997 11.9 63.997 11.2 64.097 10.1C64.197 9.00001 63.797 8.39998 62.597 8.19998L60.297 7.79999L59.697 11.5L57.797 11.2L59.397 1.19998L63.897 1.89999C66.397 2.29999 67.497 3.7 67.197 5.5C66.997 7 65.797 7.69998 64.597 7.69998C65.697 8.09998 66.197 8.9 66.097 10C65.997 11.5 65.997 12 66.197 12.4L64.197 12.2ZM62.797 6.60001C64.197 6.80001 64.997 6.49999 65.197 5.39999C65.397 4.39999 64.797 3.7 63.397 3.5L61.097 3.10001L60.597 6.19998L62.797 6.60001Z" fill="white"/>
        <path d="M74.297 15.6L67.297 12.9L70.997 3.5L77.897 6.19998L77.297 7.79999L72.197 5.79999L71.297 8.10001L75.297 9.60001L74.697 11.1L70.697 9.60001L69.697 12.1L74.897 14.1L74.297 15.6Z" fill="white"/>
        <path d="M78.397 13.8C77.597 15.2 78.197 16.4 79.397 17.1C80.397 17.8 81.397 17.8 81.797 17.1C82.197 16.5 81.897 15.9 81.097 15.1L79.697 13.8C78.497 12.7 78.097 11.4 78.997 9.99999C79.997 8.39999 81.897 8.19999 83.897 9.49999C86.097 10.9 86.597 12.9 85.397 14.8L83.797 13.9C84.497 12.7 84.097 11.6 82.997 10.9C81.997 10.3 81.197 10.3 80.697 11.1C80.297 11.7 80.597 12.2 81.297 12.9L82.697 14.2C84.197 15.6 84.397 16.9 83.497 18.3C82.397 20 80.397 19.9 78.497 18.7C76.297 17.3 75.497 15.1 76.797 13.1L78.397 13.8Z" fill="white"/>
        <path d="M82.9969 21.2L90.197 14.1L91.597 15.5L88.697 18.4L92.197 22L95.097 19.1L96.4969 20.5L89.2969 27.6L87.8969 26.2L90.9969 23.2L87.4969 19.6L84.3969 22.6L82.9969 21.2Z" fill="white"/>
        <path d="M101.097 66.6C99.597 66.2 98.597 67.1 98.197 68.5C97.797 69.7 98.0969 70.6 98.8969 70.8C99.5969 71 100.097 70.5 100.597 69.6L101.497 67.9C102.197 66.5 103.397 65.7 104.997 66.2C106.797 66.7 107.497 68.5 106.897 70.8C106.097 73.3 104.397 74.4 102.297 73.7L102.797 71.9C104.097 72.2 105.097 71.6 105.497 70.3C105.797 69.2 105.497 68.4 104.697 68.1C103.997 67.9 103.597 68.3 103.197 69.1L102.297 70.8C101.397 72.6 100.197 73.2 98.597 72.7C96.697 72.1 96.1969 70.2 96.7969 68C97.4969 65.5 99.397 64.1 101.697 64.8L101.097 66.6Z" fill="white"/>
        <path d="M98.697 74.9L104.097 77.9L103.097 79.6L97.897 76.7C96.397 75.8 95.197 76.1 94.497 77.4C93.697 78.8 94.097 79.9 95.597 80.7L100.797 83.6L99.797 85.3L94.397 82.3C91.797 80.9 91.697 78.6 92.897 76.5C94.197 74.5 96.097 73.4 98.697 74.9Z" fill="white"/>
        <path d="M88.7969 83.2L90.0969 81.7L97.5969 88.4L94.8969 91.4C93.1969 93.3 91.2969 93.6 89.6969 92.2C88.0969 90.8 88.1969 88.9 89.8969 87L91.2969 85.5L88.7969 83.2ZM91.2969 88C90.2969 89.1 90.2969 90 91.0969 90.7C91.8969 91.4 92.7969 91.3 93.7969 90.2L95.0969 88.7L92.5969 86.5L91.2969 88Z" fill="white"/>
        <path d="M77.897 92.9L83.897 88.4L89.897 96.5L83.997 100.9L82.997 99.6L87.297 96.3L85.797 94.3L82.397 96.9L81.397 95.6L84.797 93L83.197 90.9L78.697 94.2L77.897 92.9Z" fill="white"/>
        <path d="M70.897 96.3C71.197 96.5 71.597 97 72.097 98C72.597 99 73.297 99.2 74.397 98.8L76.497 97.8L74.997 94.4L76.797 93.6L80.997 102.8L76.897 104.7C74.597 105.8 72.897 105.2 72.097 103.5C71.497 102.1 71.997 100.9 72.997 100.2C71.897 100.5 70.997 100.1 70.397 99.1C69.697 97.8 69.397 97.4 68.897 97.2L70.897 96.3ZM75.097 100.1C73.797 100.7 73.397 101.4 73.797 102.4C74.197 103.3 75.097 103.6 76.397 103L78.497 102L77.197 99.1L75.097 100.1Z" fill="white"/>
        <path d="M58.697 99.2L58.897 109.3L51.697 109.4V107.7L56.897 107.6V104.9L52.897 105V103.4L56.897 103.3L56.797 99.2H58.697Z" fill="white"/>
        <path d="M43.997 98C44.197 98.3 44.097 99 43.997 100.1C43.897 101.2 44.297 101.8 45.397 102L47.697 102.4L48.397 98.7L50.297 99.1L48.397 109L43.897 108.1C41.397 107.6 40.397 106.2 40.697 104.4C40.997 102.9 42.097 102.2 43.397 102.3C42.297 101.9 41.897 101.1 41.997 99.9C42.197 98.4 42.197 97.9 41.897 97.5L43.997 98ZM45.197 103.6C43.797 103.3 42.997 103.7 42.797 104.7C42.597 105.7 43.197 106.4 44.597 106.7L46.897 107.1L47.497 104L45.197 103.6Z" fill="white"/>
        <path d="M33.8969 94.2L40.7969 97.1L36.8969 106.4L30.0969 103.5L30.7969 102L35.7969 104.1L36.7969 101.8L32.8969 100.1L33.4969 98.6L37.3969 100.3L38.3969 97.9L33.2969 95.7L33.8969 94.2Z" fill="white"/>
        <path d="M29.697 95.9C30.497 94.6 29.997 93.3 28.797 92.5C27.797 91.8 26.797 91.7 26.397 92.4C25.997 93 26.297 93.6 27.097 94.4L28.497 95.8C29.597 96.9 29.997 98.3 29.097 99.6C27.997 101.2 26.097 101.3 24.197 100C21.997 98.5 21.597 96.5 22.797 94.7L24.397 95.7C23.697 96.9 23.997 98 25.097 98.8C26.097 99.5 26.897 99.4 27.397 98.7C27.797 98.1 27.497 97.6 26.897 96.9L25.597 95.6C24.197 94.2 23.897 92.9 24.897 91.5C25.997 89.9 27.997 90 29.897 91.2C31.997 92.7 32.797 94.8 31.397 96.8L29.697 95.9Z" fill="white"/>
        <path d="M25.397 88.4L17.997 95.2L16.597 93.7L19.597 90.9L16.197 87.2L13.197 90L11.797 88.5L19.197 81.7L20.597 83.2L17.397 86.1L20.797 89.8L23.997 86.9L25.397 88.4Z" fill="white"/>
      </svg>

    )
  case 'exactly': 
    return (
      <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
        <path d="M12.6 40.1L10.7 47.4L1 44.8L2.9 37.7L4.5 38.1L3.1 43.3L5.5 43.9L6.6 39.8L8.2 40.2L7.1 44.3L9.7 45L11.1 39.6L12.6 40.1Z" fill="white"/>
        <path d="M10.8 31.8L17.3 30.8L16.2 32.9L11.6 33.6L13.7 37.7L12.7 39.7L9.90002 33.9L3.90002 34.8L5.00002 32.7L9.10003 32.1L7.20002 28.4L8.20002 26.4L10.8 31.8Z" fill="white"/>
        <path d="M13.7 19.3L24 22.7L22.6 24.3L20 23.4L17.2 26.7L18.5 29.1L17.2 30.6L12.2 20.9L13.7 19.3ZM16.6 25.3L18.6 23L14.7 21.7L16.6 25.3Z" fill="white"/>
        <path d="M21.9 11.6C24.3 9.80002 26.7 10.3 28.4 12.3L26.9 13.5C25.9 12.4 24.4 11.9 23 13C21.6 14.1 21.4 15.9 22.7 17.6C24 19.3 25.7 19.7 27.2 18.6C28.7 17.5 28.7 16 27.9 14.7L29.5 13.6C30.9 15.8 30.7 18.2 28.3 20C26 21.7 23 21.3 21.1 18.8C19.1 16.3 19.6 13.3 21.9 11.6Z" fill="white"/>
        <path d="M35.3 15.5L31.9 7.79999L28.6 9.29999L27.9 7.79999L36.3 4L37 5.5L33.7 7L37.1 14.7L35.3 15.5Z" fill="white"/>
        <path d="M41.3 2.69998L43.1 10.9L48.3 9.79999L48.7 11.4L41.5 12.9L39.4 3L41.3 2.69998Z" fill="white"/>
        <path d="M54.4 1.10001L56.6 1L53 7.39999L53.2 11.3L51.2 11.4L51 7.5L46.8 1.5L49 1.39999L51.9 5.5L54.4 1.10001Z" fill="white"/>
        <path d="M76.2 14L81 8L82.7 8.79999L76.1 16.9L74.1 16L75.4 8.60001H75.3L70.5 14.4L68.5 13.5L70.3 3.19998L72.1 4L70.7 11.5H70.8L75.7 5.60001L77.4 6.39999L76.2 14Z" fill="white"/>
        <path d="M79 18.6L85.5 10.9L87 12.2L84.4 15.3L88.2 18.5L90.8 15.4L92.3 16.7L85.9 24.4L84.4 23.1L87.2 19.8L83.4 16.6L80.6 19.9L79 18.6Z" fill="white"/>
        <path d="M98.4 24.1L92.7 33.4L91.4 31.7L92.8 29.4L90.3 25.9L87.7 26.6L86.5 25L97 22.3L98.4 24.1ZM91.9 25.5L93.7 28L95.8 24.5V24.4L91.9 25.5Z" fill="white"/>
        <path d="M93.7 35.8L101.3 32.3L99.7999 29.1L101.3 28.4L105.2 36.7L103.7 37.4L102.2 34.1L94.5999 37.6L93.7 35.8Z" fill="white"/>
        <path d="M108.3 57.7V59.9L102 56.1L98.1 56.2V54.2L102 54.1L108.1 50.1V52.3L103.9 55.1L108.3 57.7Z" fill="white"/>
        <path d="M97 64.6C97.6 61.7 100.2 60 103.2 60.7C106.2 61.4 107.9 63.9 107.2 66.8C106.6 69.7 104 71.3 101 70.7C98 70.1 96.4 67.5 97 64.6ZM105.5 66.5C105.9 64.7 104.8 63.2 102.8 62.8C100.7 62.4 99.1999 63.2 98.7999 65C98.3999 66.8 99.5 68.3 101.5 68.7C103.5 69.2 105.1 68.3 105.5 66.5Z" fill="white"/>
        <path d="M99.2 72.3L104.7 75L103.8 76.8L98.4 74.2C96.8 73.4 95.7 73.7 95 75.1C94.3 76.5 94.8 77.6 96.3 78.3L101.7 80.9L100.8 82.7L95.3 80C92.7 78.7 92.4 76.5 93.5 74.3C94.7 72.2 96.6 71 99.2 72.3Z" fill="white"/>
        <path d="M82 93.7L86.2 98.9L84.8 100.1L78.5 92.3L79.7 91.3L88.2 93.6L83.7 88.1L85.1 86.9L91.4 94.7L89.9 95.9L82 93.7Z" fill="white"/>
        <path d="M70.3 96.4L77 93L81.5 102L74.9 105.3L74.2001 103.8L79.1 101.4L78 99.2L74.2001 101.1L73.5 99.6L77.3 97.7L76.1 95.3L71.1 97.8L70.3 96.4Z" fill="white"/>
        <path d="M61.8 98.8L69.1 96.8L71.7001 106.5L64.6 108.4L64.2001 106.8L69.4 105.4L68.8 103L64.7001 104.1L64.3 102.5L68.4 101.4L67.7001 98.8L62.3 100.2L61.8 98.8Z" fill="white"/>
        <path d="M61 109.1L57.2 109.3C54 109.4 51.9 107.7 51.7 104.5C51.6 101.4 53.5 99.4 56.7 99.2L60.5 99L61 109.1ZM56.9 100.8C54.8 100.9 53.6 102.2 53.7 104.4C53.8 106.5 55.1 107.8 57.2 107.7L58.9 107.6L58.6 100.7L56.9 100.8Z" fill="white"/>
      </svg>
    )
  case 'pre-measured':
    return (
      <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
        <path d="M11.2 45.3L10.7 47.2L1 44.6L2 40.7C2.7 38.2 4.20001 37.1 6.20001 37.6C8.20001 38.1 8.99999 39.9 8.39999 42.4L7.89999 44.4L11.2 45.3ZM6.79999 42C7.19999 40.5 6.80001 39.8 5.70001 39.5C4.60001 39.2 3.9 39.7 3.5 41.1L3 43L6.20001 43.9L6.79999 42Z" fill="white"/>
        <path d="M16.2 32.7C15.9 32.8 15.2 32.6 14.2 32.1C13.2 31.7 12.4999 31.9 11.8999 32.9L10.7999 35L14.0999 36.7L13.2 38.5L4.19995 33.9L6.29993 29.8C7.49993 27.5 9.09993 26.9 10.7999 27.8C12.1999 28.5 12.4999 29.8 12.0999 30.9C12.7999 30 13.6999 29.8 14.7999 30.2C16.1999 30.8 16.6999 31 17.0999 30.8L16.2 32.7ZM10.3999 32.3C11.0999 31 10.8999 30.2 9.99994 29.7C9.09994 29.2 8.29995 29.6 7.59995 30.9L6.49994 33L9.29993 34.4L10.3999 32.3Z" fill="white"/>
        <path d="M22.6 24.1L17.8 29.9L10 23.5L14.7 17.8L16 18.9L12.6 23.1L14.5 24.6L17.2 21.3L18.5 22.3L15.8 25.6L17.9 27.3L21.4 23L22.6 24.1Z" fill="white"/>
        <path d="M19.4 19.2L22.4 16.5L23.5 17.7L20.5 20.4L19.4 19.2Z" fill="white"/>
        <path d="M31.2001 17.5L25.1001 11.9H25.0001L29.0001 18.7L27.4001 19.6L22.3 10.9L25.0001 9.3L30.6001 14.5L28.7001 7.10001L31.4001 5.5L36.5001 14.2L34.8 15.2L30.8 8.40001H30.7001L32.6001 16.5L31.2001 17.5Z" fill="white"/>
        <path d="M45.2999 11.7L38.0999 13.9L35.2 4.2L42.2999 2.10001L42.7999 3.7L37.4999 5.3L38.2 7.60001L42.2999 6.40001L42.7999 8L38.7 9.2L39.4999 11.7L44.7999 10.1L45.2999 11.7Z" fill="white"/>
        <path d="M51.2 1L56.0999 10.7L53.9999 10.9L52.7999 8.5L48.4999 8.8L47.7 11.4L45.7 11.5L48.9999 1.10001L51.2 1ZM48.9999 7.2L52.0999 7L50.2999 3.3H50.2L48.9999 7.2Z" fill="white"/>
        <path d="M58.6 7.5C58.5 9.1 59.5 9.9 60.9 10.1C62.1 10.3 63 9.9 63.1 9.1C63.2 8.3 62.6 8 61.6 7.6L59.8 7C58.3 6.5 57.3 5.5 57.6 3.8C57.9 1.9 59.5 0.899999 61.8 1.3C64.4 1.7 65.7 3.2 65.4 5.5L63.6 5.3C63.7 3.9 62.9 3.10001 61.6 2.90001C60.4 2.70001 59.7 3.2 59.6 4C59.5 4.7 60 5.10001 60.9 5.40001L62.7 6C64.6 6.6 65.4 7.7 65.2 9.3C64.9 11.3 63.1 12.1 60.8 11.8C58.2 11.4 56.6 9.80001 56.9 7.40001L58.6 7.5Z" fill="white"/>
        <path d="M67.1 8.60001L69.3 2.8L71.2 3.5L69.1 9.10001C68.5 10.7 68.9 11.8 70.3 12.4C71.8 13 72.8 12.4 73.4 10.8L75.5 5.2L77.4 5.90001L75.2 11.7C74.2 14.5 72 14.9 69.7 14C67.4 13.1 66 11.4 67.1 8.60001Z" fill="white"/>
        <path d="M80.5 19.6C80.5 19.3 80.7 18.6 81.3 17.6C81.9 16.6 81.7 15.9 80.8 15.3L78.8 14L76.7 17.1L75.1 16L80.7 7.60001L84.5 10.1C86.6 11.5 87 13.2 86 14.8C85.2 16.1 83.8 16.3 82.7 15.7C83.5 16.5 83.7 17.4 83.1 18.5C82.4 19.8 82.1 20.3 82.2 20.8L80.5 19.6ZM81.6 14C82.8 14.8 83.6 14.8 84.2 13.9C84.8 13.1 84.5 12.2 83.3 11.4L81.4 10.1L79.6 12.7L81.6 14Z" fill="white"/>
        <path d="M88.4 27L83.1 21.6L90.3 14.5L95.5 19.8L94.3 21L90.5 17.1L88.8 18.8L91.8 21.8L90.6 23L87.6 20L85.7 21.9L89.6 25.9L88.4 27Z" fill="white"/>
        <path d="M97.7 22.6L99.7 25.8C101.4 28.6 100.9 31.3 98.2 32.9C95.5 34.5 92.9 33.9 91.1 31.1L89.1 27.9L97.7 22.6ZM92.5 30.2C93.6 32 95.4 32.3 97.2 31.2C99 30.1 99.5 28.4 98.3 26.6L97.4 25.2L91.6 28.9L92.5 30.2Z" fill="white"/>
        <path d="M97.6001 47.5L97.3 45.5L107.3 44.2L107.8 48.2C108.1 50.8 107.1 52.4 105.1 52.6C103 52.9 101.6 51.6 101.3 49L101 47L97.6001 47.5ZM102.9 48.8C103.1 50.3 103.7 50.9 104.8 50.7C105.9 50.6 106.4 49.8 106.2 48.3L105.9 46.3L102.6 46.7L102.9 48.8Z" fill="white"/>
        <path d="M97.8 62.1L98.3 54.6L108.4 55.3L107.9 62.7L106.2 62.6L106.6 57.2L104.2 57L103.9 61.2L102.3 61.1L102.6 56.9L99.9001 56.7L99.5001 62.3L97.8 62.1Z" fill="white"/>
        <path d="M95.8 69.6C96.1 69.5 96.8001 69.5 97.9001 69.8C99.0001 70 99.6001 69.7 100 68.6L100.7 66.3L97.1001 65.3L97.6001 63.4L107.3 66.2L106 70.6C105.3 73.1 103.8 74 102 73.4C100.5 73 100 71.8 100.2 70.6C99.7001 71.6 98.9001 72 97.7001 71.8C96.2001 71.5 95.7 71.4 95.3 71.6L95.8 69.6ZM101.5 68.9C101.1 70.3 101.4 71.1 102.4 71.4C103.4 71.7 104.1 71.2 104.5 69.8L105.1 67.6L102.1 66.7L101.5 68.9Z" fill="white"/>
        <path d="M94.7 72.7L103.6 77.4L100.2 83.8L98.7 83L101.1 78.4L98.7 77.1L96.7999 80.7L95.3999 79.9L97.2999 76.3L93.7 74.4L94.7 72.7Z" fill="white"/>
        <path d="M85.8 85.9L90.6001 80.1L98.4001 86.5L93.7001 92.2L92.4001 91.1L95.8 86.9L93.9001 85.4L91.2001 88.7L89.9001 87.7L92.6001 84.4L90.5001 82.7L87.0001 87L85.8 85.9Z" fill="white"/>
        <path d="M88.1 97.4C85.8 99.3 83.3 98.8 81.6 97L83 95.7C84.1 96.8 85.5 97.2 87 96C88.4 94.9 88.5 93.1 87.2 91.4C85.9 89.7 84.1 89.4 82.7 90.6C81.3 91.8 81.3 93.3 82.2 94.5L80.7 95.6C79.2 93.5 79.4 91 81.7 89.2C83.9 87.4 87 87.7 88.9 90.1C90.7 92.6 90.3 95.6 88.1 97.4Z" fill="white"/>
        <path d="M74.6001 94L78.3 101.5L81.5001 99.9L82.2001 101.4L74.0001 105.5L73.3 104L76.5001 102.4L72.8 94.9L74.6001 94Z" fill="white"/>
        <path d="M69.4 106.9L66.4 97.3L68.3 96.7L71.3 106.3L69.4 106.9Z" fill="white"/>
        <path d="M60.1 98.4C63 98 65.5 99.8 65.9 102.9C66.3 106 64.4 108.4 61.5 108.8C58.6 109.2 56.2 107.4 55.7 104.3C55.4 101.2 57.2 98.8 60.1 98.4ZM61.3 107.1C63.1 106.9 64.1 105.3 63.8 103.3C63.5 101.2 62.2 100 60.3 100.3C58.5 100.5 57.5 102.1 57.8 104.1C58.1 106.1 59.4 107.3 61.3 107.1Z" fill="white"/>
        <path d="M46.9 101.9L46.2 108.6L44.4 108.4L45.4 98.4L47 98.6L51.4 106.2L52.1 99.1L53.9 99.3L52.9 109.3L50.9 109.1L46.9 101.9Z" fill="white"/>
      </svg>
    )
  case 'spoons':
    return (
      <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
      <path d="M12 42.9L10.7 47.5L1 44.8L2.2 40.5C2.9 38 4.2 37.3 5.8 37.7C6.9 38 7.5 39 7.3 40C7.8 38.9 8.9 38.2 10.2 38.6C12 39.1 12.6 40.6 12 42.9ZM5.6 44L6.2 41.7C6.5 40.5 6.1 39.9 5.4 39.7C4.7 39.5 4 39.9 3.7 41L3.1 43.3L5.6 44ZM7.1 44.4L9.8 45.1L10.5 42.6C10.8 41.4 10.5 40.7 9.7 40.5C8.9 40.3 8.2 40.7 7.9 41.9L7.1 44.4Z" fill="white"/>
      <path d="M7.5 28L8.5 26.1L12.2 32.4L15.7 34.2L14.8 35.9L11.3 34.1L4 34.8L5 32.8L10 32.3L7.5 28Z" fill="white"/>
      <path d="M22.8 24.3L18 30.1L10.2 23.7L14.9 18L16.2 19.1L12.8 23.3L14.7 24.9L17.4 21.6L18.7 22.6L16 25.9L18.1 27.6L21.6 23.3L22.8 24.3Z" fill="white"/>
      <path d="M35.3 15.5L31.1 17.7L26.6 8.7L30.6 6.7C32.9 5.6 34.3 6 35.1 7.5C35.6 8.5 35.3 9.6 34.4 10.2C35.6 9.9 36.8 10.2 37.4 11.4C38.1 13 37.4 14.5 35.3 15.5ZM30.2 11.5L32.3 10.4C33.4 9.9 33.6 9.2 33.3 8.5C33 7.8 32.3 7.6 31.2 8.1L29 9.2L30.2 11.5ZM30.8 12.9L32 15.4L34.3 14.3C35.4 13.7 35.7 13 35.4 12.3C35 11.5 34.2 11.3 33.1 11.9L30.8 12.9Z" fill="white"/>
      <path d="M43.3 2.2L45.4 1.7L43.2 8.7L44.2 12.5L42.3 13L41.3 9.2L36 4.2L38.2 3.6L41.9 7L43.3 2.2Z" fill="white"/>
      <path d="M56.3 11.1L48.8 11.4L48.4 1.3L55.8 1L55.9 2.7L50.5 2.9L50.6 5.3L54.9 5.1L55 6.7L50.7 6.9L50.8 9.6L56.4 9.4L56.3 11.1Z" fill="white"/>
      <path d="M57.8 9.2L59.9 9.4L59.7 11.4C59.6 12.7 58.9 13.4 57.4 13.3L57.5 12.3C58.2 12.3 58.5 12.1 58.6 11.4L57.6 11.3L57.8 9.2Z" fill="white"/>
      <path d="M74 15.9L75.5 7.7H75.4L71.7 14.7L70.1 13.8L74.8 4.9L77.6 6.4L76.1 13.9L81.4 8.4L84.1 9.8L79.4 18.7L77.6 17.8L81.3 10.8H81.2L75.3 16.7L74 15.9Z" fill="white"/>
      <path d="M86.4 24.9L80.8 19.9L87.5 12.3L93 17.2L91.9 18.4L87.8 14.8L86.2 16.6L89.4 19.4L88.3 20.6L85.1 17.8L83.3 19.8L87.5 23.5L86.4 24.9Z" fill="white"/>
      <path d="M98.7 24.3L93 33.6L91.7 31.9L93.1001 29.6L90.6001 26.1L88 26.8L86.8 25.2L97.3 22.6L98.7 24.3ZM92.2 25.7L94 28.2L96.2 24.7V24.6L92.2 25.7Z" fill="white"/>
      <path d="M96.9999 34C95.5999 34.7 95.3999 36 95.9999 37.3C96.4999 38.4 97.2999 39 98.0999 38.6C98.7999 38.3 98.7999 37.6 98.5999 36.6L98.1999 34.7C97.7999 33.1 98.1999 31.8 99.6999 31.1C101.4 30.3 103.1 31.2 104.1 33.4C105.2 35.8 104.5 37.7 102.5 38.7L101.7 37C102.9 36.4 103.2 35.3 102.7 34C102.2 32.9 101.5 32.5 100.7 32.9C99.9999 33.2 99.9999 33.8 100.2 34.7L100.6 36.5C101.1 38.5 100.6 39.7 99.0999 40.3C97.2999 41.1 95.5999 40 94.6999 37.9C93.6999 35.5 94.0999 33.3 96.3999 32.3L96.9999 34Z" fill="white"/>
      <path d="M100.5 41.8L106.5 40.6L106.9 42.5L101.1 43.6C99.4001 43.9 98.7001 44.8 99.0001 46.4C99.3001 48 100.3 48.5 102 48.2L107.9 47.1L108.3 49.1L102.3 50.3C99.4001 50.9 97.9 49.2 97.4 46.8C96.9 44.4 97.6001 42.3 100.5 41.8Z" fill="white"/>
      <path d="M98.2 59C98.5 58.8 99.2 58.7 100.3 58.6C101.4 58.6 102 58.1 102 56.9L102.1 54.5L98.3999 54.4L98.5 52.4L108.6 52.7L108.5 57.3C108.4 59.9 107.2 61.1 105.3 61C103.8 61 102.9 59.9 102.8 58.7C102.5 59.8 101.9 60.4 100.7 60.5C99.2 60.6 98.7 60.6 98.3 60.9L98.2 59ZM103.6 57C103.6 58.5 104 59.1 105.1 59.2C106.1 59.2 106.7 58.6 106.8 57.1L106.9 54.8L103.7 54.7L103.6 57Z" fill="white"/>
      <path d="M107.3 66.2L97.4 64.4L97.8 62.4L107.7 64.2L107.3 66.2Z" fill="white"/>
      <path d="M98.1001 73.6L104.4 75.9L103.8 77.6L94.3 74.2L94.8 72.7L103.2 70.2L96.5 67.8L97.1001 66.1L106.6 69.5L105.9 71.4L98.1001 73.6Z" fill="white"/>
      <path d="M98.1 78C100.7 79.7 101.2 82.7 99.7 85.1C98.1 87.5 95.8 88.1 93.6 86.8L94.6 85.1C95.9 85.8 97.3 85.5 98.2 84.1C99.2 82.6 98.7 80.8 97 79.7C95.2 78.5 93.4 78.8 92.3 80.4C91.5 81.6 91.6 82.9 92.5 83.9L94 81.7L95.2 82.5L92.7 86.3L88.5 83.5L89.5 82L90.7 82.8C90 81.8 90.3 80.4 91.1 79.2C92.5 76.9 95.5 76.3 98.1 78Z" fill="white"/>
      <path d="M81.2 94.7C80.3 93.4 79 93.4 77.8 94.1C76.7 94.8 76.3 95.6 76.8 96.3C77.2 97 77.9 96.9 78.9 96.5L80.7 95.8C82.2 95.2 83.6 95.4 84.4 96.8C85.4 98.4 84.8 100.2 82.7 101.4C80.4 102.8 78.5 102.4 77.3 100.5L78.8 99.5C79.6 100.6 80.7 100.8 81.9 100.1C82.9 99.5 83.2 98.7 82.8 97.9C82.4 97.3 81.8 97.3 81 97.7L79.2 98.4C77.3 99.1 76 98.8 75.2 97.4C74.2 95.7 75.1 93.9 77 92.7C79.2 91.3 81.5 91.5 82.8 93.6L81.2 94.7Z" fill="white"/>
      <path d="M70.7 96.2L72.6 95.5L76 105L72.2 106.3C69.8 107.2 68 106.5 67.3 104.5C66.6 102.5 67.6 100.9 70 100L71.9 99.3L70.7 96.2ZM70.5 101.6C69.1 102.1 68.7 102.9 69 103.9C69.4 104.9 70.2 105.3 71.6 104.8L73.5 104.1L72.4 100.9L70.5 101.6Z" fill="white"/>
      <path d="M59.3 98.8C62.2 98.5 64.6 100.3 65 103.4C65.4 106.5 63.4 108.8 60.5 109.2C57.6 109.5 55.2 107.7 54.8 104.6C54.5 101.5 56.4 99.1 59.3 98.8ZM60.3 107.4C62.1 107.2 63.2 105.7 62.9 103.6C62.7 101.5 61.3 100.3 59.5 100.5C57.7 100.7 56.6 102.2 56.9 104.3C57.2 106.4 58.4 107.7 60.3 107.4Z" fill="white"/>
      <path d="M48.5 98.7C51.4 99.1 53.3 101.5 52.9 104.6C52.5 107.7 50.1 109.5 47.1 109.1C44.2 108.7 42.3 106.3 42.7 103.2C43.2 100.2 45.6 98.3 48.5 98.7ZM47.4 107.3C49.2 107.5 50.6 106.3 50.9 104.3C51.2 102.2 50.2 100.7 48.3 100.5C46.5 100.3 45.1 101.5 44.8 103.5C44.6 105.6 45.5 107.1 47.4 107.3Z" fill="white"/>
      <path d="M34.9 98.6L32.5 104.9L30.8 104.2L34.4 94.8L35.9 95.4L38.1 103.9L40.7 97.3L42.4 98L38.8 107.4L36.9 106.7L34.9 98.6Z" fill="white"/>
      <path d="M29.8 96.3C30.6 95 30.1 93.7 28.9 93C27.9 92.3 26.9 92.3 26.5 93C26.1 93.6 26.4 94.2 27.2 95L28.6 96.4C29.7 97.5 30.2 98.8 29.2 100.2C28.1 101.8 26.2 102 24.3 100.6C22.1 99.1 21.6 97.2 22.8 95.3L24.4 96.3C23.7 97.5 24 98.6 25.2 99.3C26.2 100 27 99.9 27.5 99.2C27.9 98.6 27.6 98.1 27 97.4L25.6 96.1C24.2 94.7 23.9 93.4 24.8 92C25.9 90.4 27.9 90.4 29.8 91.7C32 93.1 32.8 95.3 31.4 97.3L29.8 96.3Z" fill="white"/>
      </svg>
    )
  case 'fresh-flavor':
      return (
        <svg width="110" height="110" viewBox="0 0 110 110" fill="none">
          <path d="M10.8 47.5L1 45L2.79999 38L4.39999 38.4L3.10001 43.4L5.70001 44.1L6.70001 40.2L8.29999 40.6L7.29999 44.5L11.3 45.5L10.8 47.5Z" fill="white"/>
          <path d="M15.8 33.5C15.5 33.6 14.8 33.4 13.8 32.9C12.7 32.5 12.1001 32.7 11.5001 33.7L10.4001 35.8L13.7001 37.5L12.8 39.3L3.80005 34.8L5.80005 30.7C6.90005 28.4 8.60005 27.8 10.3 28.6C11.7 29.3 12.0001 30.6 11.6001 31.7C12.3001 30.8 13.2 30.5 14.3 31C15.7 31.5 16.2001 31.7 16.6001 31.6L15.8 33.5ZM10.1001 33.2C10.7001 31.9 10.6001 31.1 9.60007 30.6C8.70007 30.2 7.90007 30.5 7.20007 31.8L6.20007 33.9L9.00006 35.3L10.1001 33.2Z" fill="white"/>
          <path d="M22.2 24.8L17.5 30.7L9.59998 24.4L14.2 18.6L15.5 19.6L12.1 23.9L14 25.4L16.6 22.1L17.9 23.1L15.3 26.4L17.4 28.1L20.9 23.7L22.2 24.8Z" fill="white"/>
          <path d="M21.6 20.4C22.7 21.5 24 21.4 25.1 20.4C26.1 19.6 26.3 18.7 25.8 18.1C25.3 17.5 24.7 17.7 23.7 18.2L22 19.2C20.6 20 19.2 20 18.2 18.8C17 17.4 17.3 15.5 19.1 14C21.1 12.3 23.1 12.4 24.6 14.1L23.2 15.3C22.3 14.3 21.1 14.3 20.1 15.2C19.2 16 19 16.8 19.6 17.5C20.1 18.1 20.6 17.9 21.5 17.5L23.1 16.6C24.9 15.6 26.2 15.7 27.2 17C28.5 18.5 27.9 20.4 26.1 21.9C24.1 23.6 21.8 23.8 20.3 21.9L21.6 20.4Z" fill="white"/>
          <path d="M30.1 18.2L25.5 9.2L27.3 8.3L29.2 12L33.5 9.7L31.6 6L33.4 5.1L38 14.1L36.2 15L34.2 11.2L29.8 13.5L31.8 17.3L30.1 18.2Z" fill="white"/>
          <path d="M56.2001 11.1L48.7001 11.4L48.3 1.3L55.7001 1L55.8 2.7L50.4001 2.9L50.5001 5.3L54.8 5.1L54.9001 6.7L50.6001 6.9L50.5001 9.7L56.1001 9.5L56.2001 11.1Z" fill="white"/>
          <path d="M65.7 11.1L66.4 12.3L65.1 13L64.4 11.8C63.5 12.1 62.6 12.2 61.6 12.1C58.7 11.6 56.9 9.1 57.4 6.1C57.9 3 60.4 1.3 63.3 1.8C66.2 2.3 68 4.8 67.5 7.8C67.3 9.2 66.6 10.3 65.7 11.1ZM63.1 3.5C61.3 3.2 59.9 4.3 59.5 6.4C59.1 8.5 60.1 10.1 61.9 10.4C62.5 10.5 63 10.5 63.4 10.3L62.6 8.9L63.8 8.2L64.6 9.5C65 9 65.3 8.3 65.5 7.4C65.9 5.3 65 3.8 63.1 3.5Z" fill="white"/>
          <path d="M69.2 9.5L71.6 3.8L73.5 4.7L71.1 10.2C70.4 11.8 70.7 12.9 72.2 13.5C73.7 14.1 74.6999 13.6 75.3999 12L77.7999 6.5L79.6 7.3L77.2 13C76 15.7 73.8 16 71.6 15.1C69.4 14.2 68.1 12.2 69.2 9.5Z" fill="white"/>
          <path d="M87.1 12.3L84.4 22.9L82.7 21.6L83.4 19L79.9 16.5L77.6 17.9L76 16.7L85.3 11L87.1 12.3ZM81.3 15.6L83.8 17.4L84.8 13.5H84.7L81.3 15.6Z" fill="white"/>
          <path d="M93.8 18.4L87.5 24L91 28L89.8 29.1L84.9 23.6L92.5 16.9L93.8 18.4Z" fill="white"/>
          <path d="M94.2 29.1C92.9 30 92.9 31.3 93.6 32.5C94.2 33.6 95.1 34 95.8 33.6C96.5 33.2 96.4 32.5 96.1 31.5L95.4 29.7C94.9 28.2 95.1 26.8 96.5 26C98.1 25 99.9 25.7 101.1 27.8C102.4 30.1 102 32.1 100.1 33.2L99.1 31.6C100.3 30.9 100.4 29.7 99.7 28.5C99.1 27.5 98.3 27.2 97.6 27.6C97 28 97 28.6 97.3 29.4L97.9 31.2C98.6 33.1 98.2 34.4 96.8 35.2C95.1 36.2 93.3 35.3 92.2 33.3C90.9 31.1 91.1 28.8 93.2 27.5L94.2 29.1Z" fill="white"/>
          <path d="M97.5 46.8L107.5 45.6L108.3 52.8L106.6 53L106 47.8L103.3 48.1L103.8 52.1L102.2 52.3L101.7 48.3L97.6 48.8L97.5 46.8Z" fill="white"/>
          <path d="M108.3 57.9L99.9001 57.3L99.5001 62.6L97.8 62.5L98.3 55.2L108.4 55.9L108.3 57.9Z" fill="white"/>
          <path d="M105.7 71.8L94.9 72.9L95.5 70.9L98.2 70.6L99.4 66.5L97.3 64.9L97.9 63L106.4 69.8L105.7 71.8ZM100.7 67.5L99.8 70.4L103.9 70V69.9L100.7 67.5Z" fill="white"/>
          <path d="M93.3 75.6L104.1 77L103.1 78.8L95 77.7V77.8L100.3 84.1L99.3 85.9L92.4 77.6L93.3 75.6Z" fill="white"/>
          <path d="M86.8 84.5C88.8 82.3 91.8 82.1 94.1 84.2C96.4 86.3 96.5 89.3 94.6 91.5C92.6 93.7 89.6 93.9 87.3 91.8C85 89.7 84.9 86.7 86.8 84.5ZM93.3 90.4C94.5 89 94.3 87.2 92.7 85.8C91.1 84.4 89.3 84.4 88.1 85.7C86.9 87.1 87.1 88.9 88.6 90.3C90.2 91.7 92 91.7 93.3 90.4Z" fill="white"/>
          <path d="M77.2999 92.8C77.5999 92.9 78.0999 93.4 78.7999 94.3C79.4999 95.2 80.2 95.3 81.2 94.7L83.0999 93.3L80.9999 90.2L82.5999 89.1L88.3999 97.4L84.7 100C82.6 101.5 80.8999 101.2 79.7999 99.7C78.8999 98.5 79.2 97.2 80.2 96.3C79.1 96.8 78.2999 96.6 77.4999 95.7C76.4999 94.6 76.2 94.2 75.7 94L77.2999 92.8ZM82.0999 95.9C80.8999 96.7 80.6 97.5 81.2 98.4C81.8 99.2 82.6999 99.3 83.8999 98.5L85.7999 97.2L83.9999 94.6L82.0999 95.9Z" fill="white"/>
        </svg>
      )
  default: 
    return null 
  }
}