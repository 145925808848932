import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { encode, decode } from 'src/api/gid'

import srraf from 'srraf'

import { get, find } from 'lodash'

import { Waitlist } from 'src/components/product/waitlist'

import { shopify as storefront, globalID } from "src/api/shopify";
import { productQuery } from "src/api/shopifyQueries";

import { useAddItemsToCart, useCartCount } from 'src/context/siteContext'

import { PriceList } from 'src/components/product/priceList'

const isBrowser = typeof window !== 'undefined';
const Dropdown = isBrowser ? require('src/components/product/dropdown').default : undefined

import {
  addToCart as trackAddToCart
} from 'src/components/analytics'


export const ProductForm = ({ title, customTitle, quick, variants, defaultPrice, productType, colorSetting, buttonClass, linked, productId, showQuantity, waitlist = true, addText }: {
  title: string
  defaultPrice: string
  productId: number
  quick: boolean
  productType?: string
  customTitle?: string
  variants?: any[]
  linked?: any[]
  colorSetting?: string
  buttonClass?: string
  waitlist?: boolean | true
  showQuantity?: boolean | true
  addText?: string
}) => {
  const addItemsToCart = useAddItemsToCart()

  const [stickyNav, setStickyNav] = useState(false)
  const [quantity, setQuantity] = useState(1 as number)
  const [adding, setAdding] = useState(false as boolean)
  const [pricingFixed, setPricingFixed] = useState(false as boolean)
  const [available, setAvailable] = useState(false)
  const [activeVariant, setVariant] = useState({})
  const [allVariants, setAllVariants] = useState([])
  const [activeVariantId, setActiveVariantId] = useState('' as string)
  const [compareAtPrice, setCompareAtPrice] = useState(undefined as string | undefined)
  const [check, setCheck] = useState(true)
  const [pricing, showPricing] = useState(false)
  const count = useCartCount()

  const form = React.createRef()

  useEffect(() => {
    let scroller = null
    if (check) {
      // const shopifyId = encode("Product", productId, {
      //   accessToken: process.env.GATSBY_SHOPIFY_TOKEN,
      // })
      storefront
        .fetch(productQuery, {
          id: globalID("Product", productId)
        }).then(data => {
          const product = data.product
          console.log('any product data?', product)
          const decodedVariants = [] as any

        if (product && product.variants) {
          product.variants.edges.forEach((variant: any, i) => {
            decodedVariants.push({
              ...variant.node,
              selected: i === 0 ? true : false,
              value: variant.node.id,
              label: variant.node.title,
              // cleanId: parseInt(decode(variant.node.id).id, 0),
            })
          })

          setActiveVariantId(decodedVariants[0].id as string)
          setAvailable(decodedVariants[0].availableForSale)
          setVariant(decodedVariants[0])
          setAllVariants(decodedVariants)

          console.log('decodedVariants', decodedVariants)

          if (decodedVariants[0].compareAtPrice) setCompareAtPrice(decodedVariants[0].compareAtPrice)
          if (decodedVariants[0].availableForSale) {

            let direction
            scroller = srraf(({ y, py }) => {
              if (y < py) {
                direction = 'up'
              } else {
                direction = 'down'
              }
              if (direction === 'down' && y > 1000) {
                // Apply styles
                setStickyNav(true)
              } else {
                setStickyNav(false)
              }
            })
          }
          setCheck(false)
        }
      })
    }
    () => {
      if (typeof scroller === 'function') {
        scroller.destroy()
      }
    }
  }, [check])

  const calculateVariant = (type) => {
    let baseLine = 5
    let comQt = quantity + count
    if (comQt >= 8 && comQt < 16) {
      baseLine = 4.75
    } else if (comQt >= 16 && comQt < 32) {
      baseLine = 4.5
    } else if (comQt >= 32) {
      baseLine = 4.5
    }

    if (type === 'deck') {
      comQt = (quantity * linked.length) + count
      if (comQt >= 8 && comQt < 16) {
        baseLine = 4.75
      } else if (comQt >= 16 && comQt < 32) {
        baseLine = 4.5
      } else if (comQt >= 32) {
        baseLine = 4.5
      }
    }

    return baseLine
  }

  const calculatedCost = () => {


    switch (productType) {
      case 'set':
        return parseFloat(activeVariant.price.amount)
      case 'deck':
        return linked.length * calculateVariant(productType)
      default:
        return calculateVariant()
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setAdding(true)
    const productArray = []
    const productTrack = []
    let variantActive
    
    if (linked && productType === 'deck') {
      linked.forEach(({product}) => {
        console.log('linked product', product)
        let customAttributes = []
        let variantArray = []
        product.variants.forEach(variant => {
          console.log(
            'variants', variant
          )
          // const cleanedVariant = encode('ProductVariant', variant.variantId, { accessToken: process.env.GATSBY_SHOPIFY_TOKEN })
          variantArray.push({
            variantId: globalID('ProductVariant', variant.variantId),
            sku: variant.sku,
            variantKey: variant.variantTitle,
            variantPrice: { amount: variant.variantPrice }
          })
        })
        // Handles what product we add by defualt for additional products
        variantActive = variantArray[0].variantId
        customAttributes.push({
          key: '__parentId', value: productId.toString()
        })
        customAttributes.push({
          key: '__productType', value: 'grouped'
        })
        customAttributes.push({
          key: '__rawVariants', value: JSON.stringify(variantArray)
        })
        customAttributes.push({
          key: '__parentTitle', value: title
        })
        customAttributes.push({
          key: '__productCategory', value: 'spice'
        })

        const currentPrice = parseFloat(calculateVariant()).toFixed(2).toString()

        const currentVariant = find(variantArray, { variantPrice: currentPrice })


        const productObject = {
          merchandiseId: variantActive,
          quantity,
          attributes: customAttributes
        }

        productArray.push({
          ...productObject
        })
        if (currentVariant) {

          productTrack.push({
            ...productObject,
            variant: currentVariant,
            productId: productId.toString(),
            sku: currentVariant.sku,
            price: currentVariant.variantPrice,
            title: product.title
          })
        }

      })
    } else if (productType === 'normal' || productType === 'set') {


      let customAttributes = []
      customAttributes.push({
        key: '__productCategory', value: 'non-spice'
      })
      customAttributes.push({
        key: '__productType', value: 'normal'
      })
      productArray.push({
        merchandiseId: activeVariant.id,
        quantity,
        attributes: customAttributes
      })

    } else {
      let customAttributes = []
      let variantArray = []
      if (allVariants) {
        allVariants.forEach(variant => {
          // console.log('variants???', variant, allVariants)
          // const cleanedVariant = encode('ProductVariant', variant.variantId, { accessToken: process.env.GATSBY_SHOPIFY_TOKEN })
          variantArray.push({
            variantId: variant.id,
            sku: variant.sku,
            variantKey: variant.title,
            variantPrice: variant.price
          })
        })
        // Handles what product we add by defualt for additional products
        variantActive = variantArray[0].variantId

        customAttributes.push({
          key: '__parentId', value: productId.toString()
        })
        customAttributes.push({
          key: '__productType', value: 'single'
        })
        customAttributes.push({
          key: '__rawVariants', value: JSON.stringify(variantArray)
        })
        customAttributes.push({
          key: '__productCategory', value: 'spice'
        })
      }

      // const activeVariant = get(item, 'variants[0].title')

      const currentPrice = parseFloat(calculateVariant()).toFixed(2).toString()

      const currentVariant = find(variantArray, { variantPrice: currentPrice })
      // customAttributes.push({key: '_raw', value: JSON.stringify(clonedAttributes)}
      const productObject = {
        merchandiseId: variantActive,
        quantity,
        attributes: customAttributes
      }

      productArray.push({
        ...productObject
      })

      if (currentVariant) {
        productTrack.push({
          ...productObject,
          productId: productId.toString(),
          variant: currentVariant,
          sku: currentVariant.sku,
          price: currentVariant.variantPrice,
          title: title
        })
      }
    }


    // Add all variations of items to the cart then do 
    // additional handling of analytics etc

    console.log('productArray', productArray)
    addItemsToCart(productArray).then(() => {
      trackAddToCart(productTrack)
      setAdding(false)
    })
  }

  const renderType = (type) => {
    switch (type) {
      case 'recipe':
        return (
          <div className='df bb  bc x p1 pr2 pl2 jcb'>
            <span className='courier s14'>(8) 1/2 Teaspoon Pods Included</span>
          </div>
        )
      case 'deck':
        return (
          <div className='df bb bc x p1 pr2 pl2 jcb'>
            <span className='courier s14'>({ linked && linked.length}) Cards Included</span>
          </div>
        )
      default:
        return null
    }
  }

  console.log(showQuantity, productType, addText, check, available)

  return (
    <div className=''>
      <form className='' onSubmit={(e) => handleSubmit(e)} ref={form}>
        <div className='container--m '>
          <div className='bg--white ba form__wrapper bc color--black' onSubmit={(e) => handleSubmit(e)} ref={form}>
            {available && !check ? (
              <div className=''>
                <div className='pr'>
                  {pricing && (
                    <div onClick={() => showPricing(false)} className='pa ba bc cp product__pricing-table bottom bg--white left x'>
                      <button aria-label='Open Pricing' className='pa z2 top right product__pricing-button bg--white ba bc df jcc aic'>
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                          <title>Display Pricing</title>
                          <path d="M6.50706 2.13469L2.26434 6.35143" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M6.50702 6.35139L2.26438 2.13473" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </button>
                      <PriceList />
                    </div>
                  )}
                  <div>
                    {showQuantity && renderType(productType)}
                  </div>
                  {showQuantity && linked && linked.length > 0 && (
                    <div className='courier p1 bb bc pl2--800 '>
                      {linked.map(item => (
                        <div className='s14 product__form-card' key={item._key}>
                          ({item.quantity}) - {item.product.title}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className='s24 product__form f fw jcs aist'>
                  {showQuantity && (
                    <div className={cx('product__form-qty bb bc pr2 pl2 bc--black grid--50 f jcb aic', {
                    })}>
                      <div className='f jcc pt1 pb1 aic product__form-qty-wrapper mxa'>
                        <button aria-label='Decrease Quantity' type='button' className='block rel  qty__control button--no-b db button--text founders cursor aic' onClick={() => quantity === 1 ? null : setQuantity(quantity - 1)}>
                          <svg className='cb' width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <title>Minus Icon</title><circle cx="16" cy="16" r="15" stroke="currentColor"/><path d="M21 17H12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </button>
                        <input aria-label='Current Quantity' type='number' value={quantity} onChange={e => setQuantity(parseInt(e.currentTarget.value, 10))} name='quantity' min='1' className='cb founders card-qty x tc s18' />
                        <button aria-label='Increase Quantity' type='button' className='qty__control db button--text button--no-b cursor rel  jcc aic' onClick={() => setQuantity(quantity + 1)}>
                          <svg className='cb' width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <title>Plus Icon</title><circle cx="16" cy="16" r="15" stroke="currentColor"/><path d="M16.5 12V21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/><path d="M21 16.5H12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </button>
                      </div>
                    </div>
                  )}
                  {productType === 'normal' && (
                    <div className='bb grid--50 bl bc'>
                      {isBrowser && (<Dropdown list={allVariants} defaultLabel={allVariants[0].title} updateVariant={(item) => setVariant(item)} />)}
                    </div>
                  )}
                  {productType !== 'normal' && (
                    <div className={cx('product__form-price pr bb bc grid--50 bl bc f jcb aic p1 pl2 pr2 founders  bc--black', {
                    })}>
                      <div className='s18'>
                        ${parseFloat(calculatedCost() * quantity)} 
                        <span>
                          {!addText && compareAtPrice && (
                            <small style={{ marginLeft: '4px'}} className=' strike'>${parseFloat(compareAtPrice * quantity)}</small>
                          )}
                        </span>
                      </div>
                      {productType !== 'set' && (
                        <span className='courier underline product__pricing-see ss12' onClick={() => showPricing(!pricing)}>See Pricing</span>
                      )}
                    </div>
                  )}
                  <button type='submit' className={cx('x button--no-b s18 founders button',  buttonClass, {
                    'bg--green color--white': colorSetting === 'yellow',
                    'bg--yellow': colorSetting === 'red' || colorSetting === 'blue',
                    'bg--blue color--white': colorSetting === 'green'
                  })}>
                    <div className='df jcc'>
                      <span>{adding ? 'Adding' : addText ? `${addText} | $${calculatedCost()}` : 'Add to my Pantry'}
                      
                      {addText && compareAtPrice && (
                        <small style={{ marginLeft: '4px'}} className=' s20 strike'>${parseFloat(compareAtPrice.amount * quantity)}</small>
                      )}
                      </span>
                     
                    </div>
                  </button>
                </div>
              </div>
            ): (
              <div className='product__form'>
                {!available && check && (
                  <button disabled={true} className='x button--no-b s18 founders button button__product-card caps founders'>
                    <span>Checking Stock</span>
                  </button>
                )}
                {/* 
                {waitlist ? (
                    <div className='mt1 pt1'>
                      <h5>currently Sold out</h5>
                    
                    </div>
                  ) : (
                    // Left empty for now
                    <div className='ac x bold'>
                      <span className='small'>Currently Sold Out</span>
                    </div>
                  )
                } */}
              </div>
            )}
          </div>
        </div>
        {showQuantity && available && !check && quick && (
          <div className={cx('pf z7 left x top bg--theme bb bc show--800 product__form-sticky', {
            'is-shown': stickyNav
          })}>
            <div className=''>
              <div className='row gutter--none df fw jcb'>
                <div className='col c8 '>
                  <div className='y df jcs aic'>
                    <div className='s24 pl3 db founders ws--nowrap regular pt1 pb1'>{customTitle}</div>
                  </div>
                </div>
                <div className='col c6'>
                  <div className='row gutter--none df y'>
                    <div className='col c3a'>
                      <div className='df y bl bc p1 jcc aic'>
                        <div className='df x jcb  aic'>
                          <button aria-label='Decrease Quantity' type='button' className='block rel bc qty__control button--no-b db button--text founders cursor aic' onClick={() => quantity === 1 ? null : setQuantity(quantity - 1)}>
                            <svg className='cb' width="32" height="32" viewBox="0 0 32 32" fill="none"><circle cx="16" cy="16" r="15" stroke="currentColor"/><path d="M21 17H12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/></svg>
                          </button>
                          <span className='founders s18'>{quantity}</span>
                          <button aria-label='Increase Quantity' type='button' className='qty__control bc db button--text button--no-b cursor rel  jcc aic' onClick={() => setQuantity(quantity + 1)}>
                            <svg className='cb' width="32" height="32" viewBox="0 0 32 32" fill="none"><circle cx="16" cy="16" r="15" stroke="currentColor"/><path d="M16.5 12V21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/><path d="M21 16.5H12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/></svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='col c3a'>
                      <div className='df y bl  bc p1 jcb aic'>
                        <span className='s18'>${parseFloat(calculatedCost() * quantity)}</span>
                        <button onClick={() => setPricingFixed(!pricingFixed)} type='button' className='pr qty__control button--text button--no-b'>
                          <svg className='cb' width="32" height="32" viewBox="0 0 32 32" fill="none"><circle cx="16" cy="16" r="15" stroke="currentColor"/></svg>
                          <div className='pa y x top left tc jcc aic df product__form-sticky-price founders regular s18'><span>?</span></div>
                        </button>
                      </div>
                    </div>
                    <div className='col c6a pr'>
                      <button type='submit' className='button product__form-sticky-submit founders s18 x pa y'>
                        <span>+ Add to my Pantry</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col c5' />
                <div className='col c6'>
                  <div className='row gutter--none'>
                    <div className='col c6a pr'>
                      {pricingFixed && (
                        <div onClick={() => setPricingFixed(false)} className='pa ba cp product__pricing-table bottom left x'>
                        <span className='pa bottom z2 right product__pricing-button bg--white ba bc df jcc aic'>
                          <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                            <path d="M6.50706 2.13469L2.26434 6.35143" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.50702 6.35139L2.26438 2.13473" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </span>
                        <PriceList />
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}